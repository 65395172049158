import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { ClockIcon, DollarSignIcon, TrendingUpIcon, TargetIcon } from 'lucide-react';

function TribbleROICalculator() {
  const [step, setStep] = useState(-1);
  const [inputs, setInputs] = useState({
    teamOwningResponse: '',
    rfpsPerMonth: '',
    hoursPerRfp: '',
    teamSize: '',
    avgSalary: '',
    salesReps: '',
    meetingsPerWeek: '',
    minutesPerMeeting: '',
    avgDealSize: '',
    currentWinRate: '',
    usesSlackOrTeams: '',
    enablementTimePerWeek: ''
  });
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('results');

  const getQuestions = () => {
    const baseQuestions = [
      {
        key: 'teamOwningResponse',
        question: "Which team is primarily responsible for RFPs and Security Questionnaires?",
        explanation: "This helps tailor our calculations to your organization.",
        options: ['Sales Team', 'Presales Team', 'Proposal Team', 'Other Team']
      },
      {
        key: 'rfpsPerMonth',
        question: `How many RFPs/proposals does your ${inputs.teamOwningResponse || 'team'} handle monthly?`,
        explanation: "Estimates time savings in response creation."
      },
      {
        key: 'hoursPerRfp',
        question: `On average, how many hours does your ${inputs.teamOwningResponse || 'team'} spend per RFP/proposal?`,
        explanation: "Calculates time saved with Tribble's automation."
      },
      {
        key: 'teamSize',
        question: `How many members are in your ${inputs.teamOwningResponse || 'team'}?`,
        explanation: "Helps understand the scale of your operations."
      },
      {
        key: 'avgSalary',
        question: "What's the average annual salary of a team member? (USD)",
        explanation: "Estimates potential cost savings for the team."
      },
      {
        key: 'avgDealSize',
        question: "What's your average deal size? (USD)",
        explanation: "Helps estimate potential revenue impact."
      },
      {
        key: 'currentWinRate',
        question: "What's your current win rate? (%)",
        explanation: "Calculates potential increases in sales performance."
      },
      {
        key: 'usesSlackOrTeams',
        question: "Does your organization use Slack or Microsoft Teams?",
        explanation: "Assesses the impact of Tribble's platform integration.",
        options: ['Yes', 'No']
      }
    ];

    if (inputs.usesSlackOrTeams === 'Yes') {
      baseQuestions.push(
        {
          key: 'salesReps',
          question: "How many sales representatives does your company have?",
          explanation: "Helps calculate the impact on the overall sales organization."
        },
        {
          key: 'meetingsPerWeek',
          question: "On average, how many client meetings does each sales rep have per week?",
          explanation: "Estimates time savings in meeting preparation."
        },
        {
          key: 'minutesPerMeeting',
          question: "How many minutes of prep time does a sales rep typically spend per meeting?",
          explanation: "Calculates potential savings from improved preparation."
        },
        {
          key: 'enablementTimePerWeek',
          question: "On average, how many hours per week does each sales rep spend on research and enablement activities?",
          explanation: "Helps calculate the impact of Tribble's sales enablement features."
        }
      );
    }

    return baseQuestions;
  };

  const [questions, setQuestions] = useState(getQuestions());

  useEffect(() => {
    setQuestions(getQuestions());
  }, [inputs.usesSlackOrTeams]);

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    setInputs(prevInputs => ({ ...prevInputs, [key]: value }));
  };

  const nextStep = () => {
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      calculateROI();
    }
  };

  const calculateROI = () => {
    try {
        const numInputs = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => [key, Number(value) || value])
        );

        // Time saved with RFP automation
        const rfpTimeSaved = numInputs.rfpsPerMonth * numInputs.hoursPerRfp * 0.5 * 12;
        const additionalRFPs = Math.floor(rfpTimeSaved / numInputs.hoursPerRfp);
        const additionalDeals = Math.floor(additionalRFPs * (numInputs.currentWinRate / 100));

        // Time saved with meeting preparation
        const meetingTimeSaved = numInputs.salesReps * numInputs.meetingsPerWeek * (numInputs.minutesPerMeeting / 60) * 0.3 * 52;

        // Time saved with digital sales engineer
        const enablementTimeSaved = numInputs.salesReps * numInputs.enablementTimePerWeek * 0.3 * 52;

        // Time saved with knowledge management
        const knowledgeTimeSaved = 0.25 * 52 * numInputs.salesReps;

        let totalTimeSaved = rfpTimeSaved + meetingTimeSaved + enablementTimeSaved + knowledgeTimeSaved;

        let slackTeamsImpact = 1;
        if (numInputs.usesSlackOrTeams === 'Yes') {
            slackTeamsImpact = 1.2;
            totalTimeSaved *= slackTeamsImpact;
        }

        const hourlyRate = numInputs.avgSalary / 2080;
        const totalEmployees = numInputs.teamSize + numInputs.salesReps;
        const annualCostSavings = totalTimeSaved * hourlyRate;

        const productivityIncrease = (totalTimeSaved / (2080 * totalEmployees)) * 100;

        // Adjustments for conservative revenue impact calculation
        const lowRevenueImpact = numInputs.avgDealSize * (numInputs.currentWinRate * 0.002) * numInputs.salesReps * 12; // Reduced from 0.005 to 0.002
        const highRevenueImpact = numInputs.avgDealSize * (numInputs.currentWinRate * 0.005) * numInputs.salesReps * 12; // Reduced from 0.01 to 0.005
        const inferredRevenueImpact = numInputs.avgDealSize * additionalDeals * 0.5; // Reduced by 50%
        const additionalRevenue = inferredRevenueImpact + lowRevenueImpact;

        const timeBreakdown = [
            { name: 'RFP Automation', hours: Math.round(rfpTimeSaved) },
            { name: 'Meeting Prep', hours: Math.round(meetingTimeSaved) },
            { name: 'Sales Enablement', hours: Math.round(enablementTimeSaved) },
            { name: 'Knowledge Management', hours: Math.round(knowledgeTimeSaved) }
        ].filter(metric => metric.hours > 0);

        setResults({
            totalTimeSaved: Math.round(totalTimeSaved),
            annualCostSavings: Math.round(annualCostSavings),
            productivityIncrease: productivityIncrease.toFixed(2),
            additionalRevenue: Math.round(additionalRevenue),
            timeBreakdown,
            slackTeamsImpact,
            additionalRFPs,
            additionalDeals
        });
        setStep(questions.length);
    } catch (error) {
        console.error("Error calculating ROI:", error);
        setError("An error occurred while calculating ROI. Please check your inputs and try again.");
    }
  };


  const resetCalculator = () => {
    setStep(-1);
    setInputs({
      teamOwningResponse: '',
      rfpsPerMonth: '',
      hoursPerRfp: '',
      teamSize: '',
      avgSalary: '',
      salesReps: '',
      meetingsPerWeek: '',
      minutesPerMeeting: '',
      avgDealSize: '',
      currentWinRate: '',
      usesSlackOrTeams: '',
      enablementTimePerWeek: ''
    });
    setResults(null);
    setError(null);
    setActiveTab('results');
  };

  const MetricCard = ({ icon: Icon, title, value, bgColor, textColor }) => (
    <div className={`${bgColor} p-4 rounded-lg flex flex-col items-center text-center`}>
      <Icon className={`w-6 h-6 ${textColor} mb-2`} />
      <h3 className="text-sm font-medium text-gray-500 mb-1">{title}</h3>
      <p className={`text-xl font-bold ${textColor}`}>{value}</p>
    </div>
  );

  const renderResults = () => {
    if (!results) return null;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return (
      <div className="space-y-6">
        {/* Metric cards organized in a two-column layout */}
        <div className="grid grid-cols-2 gap-6">
          {results.totalTimeSaved > 0 && (
            <MetricCard
              icon={ClockIcon}
              title="Estimated Annual Time Saved"
              value={`${results.totalTimeSaved.toLocaleString()} hours`}
              bgColor="bg-blue-50"
              textColor="text-blue-600"
            />
          )}
          {results.annualCostSavings > 0 && (
            <MetricCard
              icon={DollarSignIcon}
              title="Potential Annual Cost Savings"
              value={`$${results.annualCostSavings.toLocaleString()}`}
              bgColor="bg-green-50"
              textColor="text-green-600"
            />
          )}
        </div>
        <div className="grid grid-cols-2 gap-6">
          {results.productivityIncrease > 0 && (
            <MetricCard
              icon={TrendingUpIcon}
              title="Estimated Productivity Increase"
              value={`${results.productivityIncrease}%`}
              bgColor="bg-purple-50"
              textColor="text-purple-600"
            />
          )}
          {results.additionalRevenue > 0 && (
            <MetricCard
              icon={TargetIcon}
              title="Additional Revenue"
              value={`$${results.additionalRevenue.toLocaleString()}`}
              bgColor="bg-orange-50"
              textColor="text-orange-600"
            />
          )}
        </div>
        <div className="grid grid-cols-2 gap-6">
          {/* Time Saved Breakdown Chart */}
          {results.timeBreakdown.length > 0 && (
            <div className="bg-white shadow rounded-lg p-4">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Time Saved Breakdown</h3>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={results.timeBreakdown} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="hours" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
          {/* Additional RFPs and Deals Chart */}
          <div className="bg-white shadow rounded-lg p-4">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Additional Deals from RFP Efficiency</h3>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={[
                    { name: 'Additional RFPs Processed', value: results.additionalRFPs },
                    { name: 'Additional Deals Won', value: results.additionalDeals }
                  ]}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#82ca9d"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {[
                    { name: 'Additional RFPs Processed', value: results.additionalRFPs, fill: '#8884d8' },
                    { name: 'Additional Deals Won', value: results.additionalDeals, fill: '#82ca9d' }
                  ].map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.fill} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="lg:col-span-3">
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">How We Calculated Your ROI</h3>
            <p className="mb-4 text-gray-600">Here's a detailed breakdown of how we arrived at these conservative estimates:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li><strong>Time Savings:</strong> We estimated that Tribble could help save 50% of time on RFPs, 30% on meeting prep (based on the minutes you provided), and 30% on research and enablement activities.</li>
              <li><strong>Cost Savings:</strong> We multiplied the total hours saved by the average hourly rate (based on the salary you provided) to estimate potential cost savings.</li>
              <li><strong>Productivity Increase:</strong> We calculated this by dividing the total time saved by the total working hours of all employees (assuming 2080 hours per year per employee).</li>
              <li><strong>Revenue Impact:</strong> We projected a very modest 0.5-1% improvement in win rate and applied this to your current deals to estimate potential additional revenue. Additionally, based on the average deal size you provided, we inferred that answering more RFPs could lead to additional deals, adding $${results.additionalRevenue.toLocaleString()} to your revenue. By saving time, your team can process ${results.additionalRFPs} more RFPs annually, leading to approximately ${results.additionalDeals} more deals based on your win rate.</li>
            </ul>
            <p className="mt-4 text-gray-600">These calculations use very conservative estimates to provide a realistic baseline projection of Tribble's potential impact. We believe in transparency and want you to fully understand how these numbers were derived to gain your trust and confidence in our solution.</p>
          </div>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-8">
            <p className="text-sm text-yellow-700"><strong>Note:</strong> These calculations are estimates based on industry averages and conservative assumptions. Actual results may vary depending on your specific implementation and usage of Tribble. We recommend a thorough evaluation to determine the actual impact on your organization.</p>
          </div>
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    const question = questions[step];
    return (
      <div className="bg-white shadow rounded-lg p-6 w-full max-w-2xl mx-auto">
        <h3 className="text-xl font-bold text-gray-900 mb-2">{question.question}</h3>
        <p className="text-sm text-gray-600 mb-4">{question.explanation}</p>
        {question.options ? (
          <select
            value={inputs[question.key]}
            onChange={(e) => handleInputChange(e, question.key)}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select an option</option>
            {question.options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        ) : (
          <input
            type="number"
            value={inputs[question.key]}
            onChange={(e) => handleInputChange(e, question.key)}
            placeholder="Enter your answer"
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        )}
      </div>
    );
  };

  const renderInputs = () => {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Adjust Your Inputs</h3>
        <div className="space-y-4">
          {questions.map((q) => (
            <div key={q.key}>
              <label className="block text-sm font-medium text-gray-700 mb-1">{q.question}</label>
              {q.options ? (
                <select
                  value={inputs[q.key]}
                  onChange={(e) => handleInputChange(e, q.key)}
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select an option</option>
                  {q.options.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              ) : (
                <input
                  type="number"
                  value={inputs[q.key]}
                  onChange={(e) => handleInputChange(e, q.key)}
                  placeholder="Enter your answer"
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            </div>
          ))}
        </div>
        <button
          onClick={calculateROI}
          className="mt-6 w-full bg-blue-600 text-white px-4 py-2 rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300"
        >
          Recalculate ROI
        </button>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <div className="max-w-6xl mx-auto px-4 py-8">
        {step === -1 ? (
          <div className="text-center">
            <h2 className="text-4xl font-bold mb-4 text-gray-900">Tribble ROI Calculator</h2>
            <p className="mb-8 text-gray-600 text-lg">
              Discover how Tribble can transform your team's productivity, enhance sales enablement, and boost your bottom line.
            </p>
            <button
              onClick={() => setStep(0)}
              className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Start Calculator
            </button>
          </div>
        ) : step < questions.length ? (
          <div>
            <div className="mb-8 bg-gray-200 h-2 rounded-full">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${((step + 1) / questions.length) * 100}%` }}
              ></div>
            </div>
            {renderQuestion()}
            <div className="mt-8 text-center">
              <button
                onClick={nextStep}
                className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300"
              >
                {step === questions.length - 1 ? 'Calculate ROI' : 'Next'}
              </button>
            </div>
          </div>
        ) : results ? (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Your ROI Results</h2>
              <div className="flex space-x-4">
                <button
                  onClick={() => setActiveTab('results')}
                  className={`px-4 py-2 rounded-md ${activeTab === 'results' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                >
                  ROI Metrics
                </button>
                <button
                  onClick={() => setActiveTab('inputs')}
                  className={`px-4 py-2 rounded-md ${activeTab === 'inputs' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                >
                  Adjust Inputs
                </button>
              </div>
            </div>
            {activeTab === 'results' ? renderResults() : renderInputs()}
            <div className="mt-8 flex justify-center space-x-4">
              <button
                onClick={() => window.location.href = "https://tribble.com/book-a-call"}
                className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300"
              >
                Book a Demo
              </button>
              <button
                onClick={resetCalculator}
                className="bg-gray-200 text-gray-800 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-300 transition duration-300"
              >
                Start Over
              </button>
            </div>
          </div>
        ) : error ? (
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-gray-600">Calculating your potential ROI...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TribbleROICalculator;
